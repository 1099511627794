@import './index.scss';

.home-section {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../images/dolphinWaves2.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .content-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        .heading {
            text-align: center;
            font-size: 24px;
        }
    
        .subheading {
            text-align: center;
            font-size: 88px;    
        }

        .cta-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
            margin-top: 20px;
            z-index: 7;
            font-size: 18px;

            .login-cta {
                color: #081b4b;
                background-color: #fff;
                padding: 15px 40px;
                border-radius: 10px;
                font-weight: 500;
                box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
                transition: all 0.2s ease 0s;

                &:hover {
                    background-color: #ebebeb;
                    box-shadow: rgb(8 27 75 / 35%) 0px 2px 0px;
                }
            }

            .signup-cta {
                color: #fff;
                background-color: $primary-color;
                padding: 15px 40px;
                border-radius: 10px;
                font-weight: 500;
                box-shadow: rgb(8 27 75 / 35%) 0px 6px 20px -6px;
                transition: all 0.2s ease 0s;

                &:hover {
                    background-color: #103b7c;
                    box-shadow: rgb(8 27 75 / 35%) 0px 2px 0px;
                }
            }
        }
    }

    .patternContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // background-color: $primary-color;
        mix-blend-mode: color;
    }
}

@media screen and (max-width: 768px) {
    .home-section {
        background-position: 40% 50%;

        .content-wrapper {
            .heading {
                font-size: 16px;
            }
    
            .subheading {
                font-size: 48px;
            }
    
            .cta-btns {
                gap: 10px;
                font-size: 14px;
            }
        }
    }
}