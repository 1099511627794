.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
      svg {
        transform: rotate(-90deg);
      }
    }

    .right {
      svg {
        transform: rotate(90deg);
      }
    }

    .paginate-btn {
      color: #5b6987;
      background-color: transparent;
      border: none;
      padding: 12px 9px;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

      svg {
        height: 8px;
        fill: #5b6987;
      }
      
      &:disabled {
        cursor: default !important;
        
        svg {
          fill: #ddd;
        }
      }

      &:hover:enabled {
        background-color: #F3F5F9;
      }
    }

    .form-text {
      font-weight: 500;
      font-size: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .user-page-input {
      padding: 12px 7px;
      width: 31px;
      height: 31px;
      text-align: center;
      border: 1px solid #5B6987;
      border-radius: 3px;
      color: #000;
    }
  }
}
