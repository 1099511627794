@import "index.scss";
@import 'authPages.scss';

.signup-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    background-image: url('../images/blueFishBanner2.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .green {
        background-color: #107C43 !important;

        &:hover {
            background-color: #0AA653 !important;
        }
    }

    .login-cta {
        position: absolute;
        bottom: 80px;
        color: #777;
        font-size: 14px;

        a {
            color: $primary-color;
            font-weight: 700;
        }
    }
}

@media (max-width: 768px) {
    .signup-page {
        background-image: none;
    }

    .seaFloorPlantLeft {
        width: 200px;
        height: 74px;
      }
}