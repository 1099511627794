@import "index.scss";
@import 'authPages.scss';

.create-part-wrapper {
    .link-text {
        display: flex;
        font-family: 'Monsterrat', sans-serif;
        font-size: 14px;
        color: #5b6987;
        background-color: #fff;
        border: none;
        cursor: pointer;

        svg {
            height: 14px;
            width: 14px;
            margin-right: 14px;
            fill: #5b6987;
        }

        &:hover {
            color: #8390af;
        }

        &:hover svg {
            fill: #8390af;
        }
    }

    .create-part-heading {
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 40px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 45px;
    }

    .create-part-form {
        .section-heading {
            font-family: "Helvetica Neue", sans-serif;
            font-weight: 500;
            margin-bottom: 32px;
            font-size: 24px;
        }

        .grid-input-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            max-width: 920px;
            margin-bottom: 32px;

            .input-group {
                width: 450px;
                display: flex;
                flex-direction: column;

                .form-input-label {
                    font-size: 16px;
                    font-family: "Helvetica Neue", sans-serif;
                    color: $blue-1;
                    font-weight: 400;

                    .required{
                        color: #ff3a2e;
                    }
                }

                .form-input-field {
                    font-size: 14px;
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid #8390AF;
                    padding: 13px 15px;
                    font-family: "Helvetica Neue", sans-serif;
                    margin-top: 7px;
                    transition: all .25s cubic-bezier(.645,.045,.355,1);

                    &:hover {
                        border-color: $primary-color;
                    }
                }

                .price-input-container {
                    display: flex;
                    margin-top: 7px;
                    width: auto;

                    &:hover {
                        border-color: $primary-color;
                    }

                    .input-currency-symbol {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 15px;
                        font-size: 14px;
                        color: #8390AF;
                        background-color: #f7f7f7;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        border: 1px solid #8390AF;
                    }

                    .price-input-placeholder {
                        input {
                            padding: 12px 15px;
                            height: 100%;
                            border-left: none;
                            border-top: 1px solid #8390AF;
                            border-bottom: 1px solid #8390AF;
                            border-right: 1px solid #8390AF;
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;
                            border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;
                        }
                    }
                }
            }
        }

        .submit-section {
            margin-top: 60px;

            .create-part-btn {
                padding: 12px 25px;
                border: none;
                border-radius: 3px;
                color: #fff;
                background-color: #107C43;
                margin-top: 16px;
                cursor: pointer;
                transition: all 0.1s ease-in;
                margin-right: 15px;
        
                &:hover {
                    background-color: #0AA653 !important;
                }
            }
        }


        .error-card {
            margin-bottom: 10px;
            padding: 16px;
            width: 100%;
            height: auto;
            background-color: #ffe2e0;
            border: 1px solid #820800;
            color: #000;
            font-size: 16px;
            border-radius: 10px;
        }
    
        .error-message {
            color: #ca0c0c;
            font-size: 14px;
            margin-top: 5px;
        }
    
        .error-input-field {
            border-color: #ff3a2e !important;
        }

        .location-quantity-selector {
            display: flex;
            flex-direction: column;
            position: relative;

            .selector-wrapper {
                margin-bottom: 20px;
            }

            .add-location-btn {
                margin-top: 20px;
                color: $primary-color;
                background-color: #fff;
                border: 2px solid $primary-color;
                border-radius: 3px;
                padding: 16px;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

                &:hover {
                    color: #fff;
                    background-color: $primary-color;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .create-part-wrapper {
        .create-part-form {
            .grid-input-container {
                grid-template-columns: repeat(1, 1fr);

                .input-group {
                    width: 100%;

                    .form-input-field {
                        width: 100%
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .create-part-wrapper {
        .create-part-heading {
            font-size: 32px;
        }

        .submit-section {
            .create-part-btn {
                margin-top: 30px !important;
            }
        }
    }
}

