@import 'index.scss';
@import 'authPages.scss';

.login-section {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #EFF2FB;

  .login-form-section {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .register-cta {
      position: absolute;
      bottom: 80px;
      color: #777;
      font-size: 14px;

      a {
        color: $primary-color;
        font-weight: 700;
      }
    }
  }

  .login-hero-right {
    width: 50%;
    height: 100%;
    background: url('../images/deepBlueOcean.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
    text-align: center;

    .heading {
      font-size: 32px;
      // color: #031B4E;
      color: #fff;
      margin-bottom: 16px;
      letter-spacing: -1px;
    }

    .subheading {
      font-size: 18px;
      // color: #031B4E;
      color: #fff;
      line-height: 32px;
    }
  }
}

@media (max-width: 1199px) {
  .login-section {
    background-color: #fff;

    .login-form-section {
      width: 100%;
      flex-direction: column;
    }
  }
}