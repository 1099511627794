@import './index.scss';

.aurora-container {
    display: flex;
    width: 100%;
    height: auto;

    .aurora-container-right {
        padding-left: 125px;
        padding-top: 45px;
        padding-right: 45px;
        padding-bottom: 45px;
        width: 100%;
        height: auto;

        .dashboard-heading {
            margin-bottom: 60px;
            display: flex;
            flex-direction: column;
            
            h1 {
                font-family: "Helvetica Neue", sans-serif;
                font-size: 40px;
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .aurora-container {
        .aurora-container-right {
            padding: 100px 10px 45px 10px;
        }
    }
}
  