.inventory-view-container {
    width: 100%;

    .row-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .create-part-btn {
        font-size: 14px;
        font-family: "Helvetica Neue";
        padding: 12px 17px; 
        background-color: #127C43;
        border: none;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color 0.1s ease-in;

        &:hover {
            background-color: #0AA653;
        }
    }

    .parts-available {
        font-size: 20px;
        font-weight: 500;
        font-family: "Helvetica Neue", sans-serif;
    }

    .filter-query-container {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
    }

    
    .table-container {
        width: 100%;

        .no-parts-msg {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0;
        }
        
        .loading-parts-text {
            width: 100%;
            display: flex;
            padding: 24px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: #676767;
            font-size: 14px;
        }

        .parts-table {
            border-collapse: collapse;
            border: 2px solid #F1F1F1;
            border-radius: 3px;
            width: 100%;

            .table-heading {
                background-color: #FAFAFA;
                
                tr {
                    th {
                        padding: 8px 16px;
                        font-size: 14px;
                        color: #676767;
                        text-align: left;
                        border-bottom: 2px solid #F1F1F1;
                    }
                }
            }

            .table-body {
                tr {
                    transition: all 0.1s ease-in;
                    padding: 1rem;

                    &:hover {
                        background-color: #F3F5F9;
                    }

                    td {
                        border-bottom: 2px solid #F1F1F1;
                        padding: 16px 0;
                        color: #676767;
                        font-size: 14px;

                        &:first-child {
                            padding-left: 16px;
                        }

                        &:last-child {
                            padding-right: 16px;
                        }
                    }


                    .location-td {

                        div {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }
                    }

                    .bold-td {
                        font-weight: 700;
                        text-align: left;
                    }

                    .price-td {
                        font-weight: 700;
                        color: #127C43;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .mobile-align-right {
        text-align: right !important;
    }

    .parts-table {
        .table-heading {
            tr {
                th {
                    font-size: 12px !important;
                }
            }
        }

        .table-body {
            tr {
                .mobile-view-part-heading {
                    .part-number {
                        font-weight: 700;
                        font-size: 14px;
                    }

                    .part-name {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}