@import './index.scss';

.seaFloorPlantLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url('../images/seaFloorLeft.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 74px;
}

.seaFloorPlantRight {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url('../images/seaFloorRight.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 53px;
    width: 198px;
}

.form-card {
    padding: 32px 40px;
    background-color: #fff;
    border: 1px solid #5B6987;
    border-radius: 25px;
    box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
    z-index: 7;

    .card-heading {
        font-size: 32px;
        color: #031B4E;
        margin-bottom: 20px;
    }

    .authentication-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;

        .form-input-label {
            font-size: 16px;
            color: $primary-color;
            font-family: "Ubuntu Mono";
            margin-bottom: 5px;

            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        .form-input-field {
            width: 400px;
            border-radius: 10px;
            border: 1px solid #8794B0;
            padding: 15px;
            font-family: "Ubuntu Mono";
        }

        .submit-form-btn {
            padding: 15px 0;
            background-color: $primary-color;
            border: none;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            transition: all 0.1s ease-in;
            margin-bottom: 10px;
            margin-top: 20px;

            &:hover {
                background-color: #3387FF;
            }
        }
    }

    .loading-message {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 10px 0;
        color: $blue-1;

        .Loader--basic {
            width: 20px;
            height: 20px;
        }
    }

    .error-card {
        margin-bottom: 10px;
        padding: 16px;
        width: 100%;
        height: auto;
        background-color: #ffe2e0;
        border: 1px solid #820800;
        color: #000;
        font-size: 16px;
        border-radius: 10px;
    }

    .error-message {
        color: #ca0c0c;
        font-size: 14px;
        margin-top: 5px;
    }

    .error-input-field {
        border-color: #ff3a2e !important;
    }

    .forgot-password-link {
        color: $primary-color;
        font-size: 16px;
        text-decoration: underline;
        font-family: "Ubuntu Mono";
    }
}

@media (max-width: 768px) {
    .password-input-field {
        letter-spacing: 2px;
    }

    .form-card {
        width: 100%;
        padding: 0 20px;
        border: none;
        box-shadow: none;
        border-radius: 0;

        .form-input-field {
            width: 100% !important;
        }

        .card-heading {
            font-size: 24px;
        }
    }
}