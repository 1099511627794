// Colors
$primary-color: #0069FC;
$blue-1: #031b4d;
$blue-2: #062D9B;
$purple-dark: #9086FA;
$purple-light: #F7F6FE;
$green-dark: #107C43;
$green-light: #D4EFEE;
$green-lightest: #EFF6F2;
$success: #15CD72;
$danger: #FF3A2E;
$info: #3387FF;
$warning: #FCBF26;

// Epilogue
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Ubuntu Mono
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
// Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//Monsterrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Epilogue";
    margin: 0;
}

a {
    text-decoration: none;
}

// Utilities
.Loader--basic {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 2px solid $primary-color;
    border-bottom-color: transparent;
    display: inline-block;
    animation: rotate .75s 0s linear infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    
    100% {
        transform: rotate(360deg);
    }
}