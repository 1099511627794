@import './index.scss';

.success {
    color: $success;
}

.danger {
    color: $danger;
}

.info {
    color: $info;
}

.warning {
    color: $warning;
}

.show {
    transform: translate3d(-20px, 0, 0) !important;
}

.toast-container {
    padding: 30px 25px;
    margin: 20px 0;
    background: #fff;
    cursor: pointer;
    position: relative;
    pointer-events: auto;
    border-radius: 3px;
    border: 2px solid currentColor;
    box-shadow: rgb(0 0 0 / 12%) 0 1px 3px;
    transform: translate3d(calc(100% + 12.5px), 0, 0);
    transition: 0.5s ease;

    span {
        font-size: 16px;
        color: currentColor;
        font-family: "Helveitca Neue", sans-serif;
        font-weight: 300;
        font-style: normal;
        line-height: 1.5;
        white-space: nowrap;
    }

    button {
        font-size: 0;
        border: 1px solid transparent;
        background-color: #fff;
        padding: 0;
        cursor: pointer;
        color: currentColor;

        &::after {
            content: '\2715';
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
        }
    }
}