@import './index.scss';

.aurora-container-left {
    background-color: #fff;
    width: 80px;
    min-height: 200px;
    height: 100%;
    max-height: 100vh;
    padding: 45px 30px 20px 30px;
    border-right: 1px solid #dfdfdf;
    box-shadow: 1px 2px 4px 0 rgb(0 0 0 / 3%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transition: width 0.25s ease-in;
    position: fixed;
    z-index: 7;

    .account-card {
        position: absolute;
        top: -100px;
        width: 200px;
        height: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid #dfdfdf;
        border-radius: 2px;
        box-shadow: 0px 2px 8px rgba(0,0,0, 0.03);

        a, button {
            background-color: #fff;
            border: none;
            text-align: left;
            padding: 10px 15px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 300;
            color: #000;

            &:hover {
                background-color: #E5E8ED;
            }
        }

        a {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        button {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .top-menu-wrapper {
        display: flex;
        flex-direction: column;
        gap: 35px;

        .menu-link {
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 14px;
            font-weight: 400;
            transition: all 0.05s ease-in;
            transition-delay: 0.1s;

            span {
                opacity: 0;
                font-weight: 700;
                color: #000;
            }

            svg {
                width: 20px;
                height: 20px;
                fill: #000;
            }
        }
    }

    .bottom-menu-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        .user-info {
            display: flex;
            flex-direction: column;
            gap: 3px;
            opacity: 0;

            .user__name {
                font-size: 14px;
                font-weight: 700;
            }

            .user__email {
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}

.nav-open {
    width: auto;

    .top-menu-wrapper {
        width: 100%;
        
        .menu-link {
            padding-bottom: 5px;
            border-bottom: 1px solid #000;
            width: 100%;

            span {
                opacity: 1;
                transition: all 0.05s ease-in;
                transition-delay: 0.1s;
            }

            div {
                border-color: #000;
        
                div {
                    background-color: #000;
                }
            }
        }
    }

    .bottom-menu-wrapper {
        .user-info {
            opacity: 1;
            transition: all 0.1s ease-in;
            transition-delay: 0.2s;
        }
    }
}

@media screen and (max-width: 768px) {
    .aurora-container-left {
        position: absolute;
        border-right: none;
        box-shadow: none;
        padding: 25px 10px;
        background-color: transparent;
        min-height: 0;
        height: 50px;
        gap: 50px;
        justify-content: flex-start;

        .top-menu-wrapper {

            .menu-link {
                display: none;
            }
        }

        .bottom-menu-wrapper {
            span {
                display: none;
            }
        }
    }

    .nav-open {
        position: fixed;
        width: 75%;
        border-right: 1px solid #dfdfdf;
        background-color: #fff;
        height: 100vh;

        .account-card {
            top: 40px;
            width: 250px;
        }

        .top-menu-wrapper {
            
            .menu-link {
                display: flex;
            }
        }
    
        .bottom-menu-wrapper {
            // margin-bottom: 150px;

            span {
                display: flex;
            }
        }
    }
}