@import "index.scss";

.update-part-wrapper {
    .delete-btn {
        color: #ca0c0c !important;

        &:hover {
            text-decoration: underline;
        }
    }

    .link-text {
        display: flex;
        font-family: 'Monsterrat', sans-serif;
        font-size: 14px;
        color: #5b6987;
        background-color: #fff;
        border: none;
        cursor: pointer;

        svg {
            height: 14px;
            width: 14px;
            margin-right: 14px;
            fill: #5b6987;
        }

        &:hover {
            color: #8390af;
        }

        &:hover svg {
            fill: #8390af;
        }
    }

    .create-part-heading {
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 40px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 45px;
    }

    .price-input-container {
        display: flex;
        margin-top: 7px;
        margin-bottom: 10px;
        width: auto;

        &:hover {
            border-color: $primary-color;
        }

        .input-currency-symbol {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 15px;
            font-size: 14px;
            color: #8390AF;
            background-color: #f7f7f7;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border: 1px solid #8390AF;
        }

        .price-input-placeholder {
            input {
                padding: 12px 15px;
                height: 100%;
                border-left: none;
                border-top: 1px solid #8390AF;
                border-bottom: 1px solid #8390AF;
                border-right: 1px solid #8390AF;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
            }
        }
    }

    .currency {
        font-weight: 700;
        color: #127C43 !important;
    }

    .setting-current {
        .add-new__btn {
            margin-bottom: 25px;
            cursor: pointer;
        }

        span {
            color: #5b6987;
        }

        .location-list {
            display: flex;
            flex-direction: column;
            gap: 15px;

            h4 {
                font-family: "Helvetica Neue", sans-serif;
                font-size: 18px;
                font-weight: 500;
            }

            div {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }

    .edit-location-row-wrapper {
        margin-bottom: 30px;

        .selection-wrapper {
            margin-top: 10px;
            border-bottom: none;
            padding-bottom: 0;
    
            &:not(:last-child) {
                border-bottom: 1px #e5e8ed solid;
                padding-bottom: 25px;
            }
        }
    }

}