@import "index.scss";
@import 'authPages.scss';

.forgot-password {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    background-image: url('../images/new-brand-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .form-card {
        max-width: 500px;
        padding: 24px;
        border-color: #C6CDDC !important;
        border-radius: 25px;

       .card-description {
            color: #4D5B7C;
            font-size: 16px;
            margin-bottom: 30px;
            line-height: 28px;
       }

       .email-sent-msg {
            background: rgb(212, 239, 238);
            padding: 16px;
            border-radius: 10px;
            border: 1px solid rgb(0, 89, 85);
            color: #000;
            line-height: 28px;
       }

       .authentication-form {
        .form-input-field {
            width: auto !important;
        }
       }
    }
}

@media screen and (max-width: 768px) {
    .forgot-password {
        padding: 10px;

        .form-card {
            border: 1px solid #C6CDDC !important;
        }
    }
}