@import './index.scss';

.auth-nav {
    height: 65px;
    width: 100%;
    padding: 0 32px;
    background-color: #fff;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 3px;
    z-index: 777;

    .brand {
        height: 35px;
    }

    .auth-cta {
        font-size: 14px;
        color: #777;

        a {
            font-size: 16px;
            text-decoration: underline;
            font-weight: 500;
            color: $primary-color;
            font-family: "Ubuntu Mono";
            margin-left: 10px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}