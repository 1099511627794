@import './index.scss';

.selection-wrapper {
    display: flex;
    gap: 25px;
    align-items: center;

    &:not(:last-child) {
        padding-bottom: 50px;
        border-bottom: 1px #e5e8ed solid;
    }

    .input-wrapper {
        display: flex;
        gap: 20px;

        .selection-heading {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            font-family: "Helvetica Neue", sans-serif;
        }
    
        .quantity-input {
            display: flex;
            height: 48px;
    
            .quantity-input {
                width: 54px;
                border-radius: 0;
                border: 1px solid #5b6987;
                text-align: center;
                transition: border-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    
                &:hover {
                    border-color: $primary-color;
                }
            }
    
            button {
                width: 48px;
                background-color: #fff;
                border: 1px solid #5b6987;
                cursor: pointer;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    
                svg {
                    width: 12px;
                    height: 12px;
                    fill: #5b6987;
                }
    
                &:hover, &:hover svg {
                    border-color: $primary-color;
                    fill: $primary-color;
                }
            }
    
            .increase {
                border-bottom-left-radius: 3px;
                border-top-left-radius: 3px;
                border-right: none;
            }
            
            .decrease {
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
                border-left: none;
            }
        }
    
        .location-input {
    
            .location-selector-btn {
                height: 48px;
                width: 320px;
                border: 1px solid #5b6987;
                padding: 0 16px;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;
                color: #000;
                font-weight: 500;
                cursor: pointer;
            
                span {
                    font-family: 16px;
                    font-family: "Helvetica Neue", sans-serif;
                    color: #000;
                    font-weight: 500;
                }
            
                svg {
                    height: 14px;
                    width: 14px;
                    fill: #5b6987;
                }
            }
    
            .open {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-color: $primary-color !important;
                border-bottom-color: #fff !important;
            }
            
            .expanded-menu {
                position: absolute;
                background-color: #fff;
                border: 1px solid $primary-color;
                width: 320px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            
                ul {
                    list-style: none;
            
                    li {
                        padding: 8px 16px;
                        cursor: pointer;
            
                        &:hover {
                            background-color: #e5e8ed;
                            font-weight: 500;
                        }
                    }
    
                    .currently-selected {
                        background-color: #5b6987 !important;
                        color: #fff !important;
                        font-weight: 500;
    
                        &:hover {
                            background-color: #5b6987 !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }

    .delete-btn {
        padding: 16px;
        background-color: #fff;
        border: none;
        cursor: pointer;
        width: auto;
        height: auto;
        margin-top: 25px;
        border-radius: 3px;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

        svg {
            width: 14px;
            height: 14px;
            fill: #5b6987;
        }

        &:hover {
            background-color: #fef8f4;
        }

        &:hover svg {
            fill: #F5610E;
        }
    }
    
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .selection-wrapper {
        align-items: flex-start;
        gap: 5px;
        
        .input-wrapper {
            flex-direction: column;
        }
    }
}