@import "index.scss";
@import "authPages.scss";

.account-wrapper {
    .account-heading, .account-subheading {
        font-family: "Helvetica Neue", sans-serif !important;
        color: #000;
    }

    .account-heading {
        font-size: 32px;
        margin-bottom: 5px;
        font-weight: 700;
    }

    .account-subheading {
        font-size: 16px;
        font-weight: 400;
    }

    .navbar {
        border-bottom: 1px solid #8390AF;
        width: 100%;
        list-style: none;
        padding: 0px;
        background: none;
        margin: 0px;
        margin-top: 30px;

        .list-item {
            
            button {
                height: 40px;
                padding: 0 16px;
                border-width: 1px;
                border-style: solid;
                border-color: rgb(131, 144, 175) rgb(131, 144, 175) white;
                border-radius: 3px 3px 0px 0px;
                background-color: #fff;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: -1px;
                color: $blue-1;
                cursor: pointer;

                &:hover {
                    background-color: #f3f5f9;
                }
            }
        }
    }

    .settings-container {
        margin-top: 16px;

        .warning {
            color: $success;
            font-size: 14px;
        }

        .settings-item {
            display: flex;
            justify-content: space-between;
            margin: 24px 0;
            padding-bottom: 24px;

            &:not(:last-child) {
                border-bottom: 1px solid #e5e8ed;
            }

            .setting-heading {
                display: flex;
                flex: 0 1 calc(100% - 120px);
                max-width: 25%;
                padding-right: 1%;

                h4 {
                    font-family: "Helvetica Neue", sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .setting-current {
                display: flex;
                flex: 0 0 100%;
                max-width: 50%;
                padding-right: 1%;

                p {
                    font-size: 16px;
                    font-weight: 300;
                }

                .form-update-user {
                    display: flex;
                    flex-direction: column;

                    .update-heading {
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 5px;

                        span {
                            color: #3387FF;
                        }
                    }

                    .form-input-field {
                        height: 48px;
                        padding: 0 16px;
                        border-radius: 3px;
                        border: 1px solid #5b6987;
                        min-width: 250px;
                        max-width: 500px;
                        transition: all 0.25s ease-in;
                        margin-bottom: 10px;

                        &:hover {
                            border-color: $primary-color;
                        }
                    }

                    .error-message {
                        margin-top: 2px;
                        margin-bottom: 15px;
                        font-size: 14px;
                        color: $danger;
                    }

                    .btn-wrapper {
                        display: flex;
                        gap: 20px;

                        .save-btn {
                            background-color: $primary-color;
                            color: #fff;
                            padding: 12px 16px;
                            border: none;
                            border-radius: 3px;
                            transition: all 0.15s ease-in;
                            cursor: pointer;
                            font-size: 16px;
                            font-weight: 400;

                            &:hover {
                                background-color: #3387FF;
                            }
                        }

                        .cancel-btn {
                            background-color: transparent;
                            padding: 0;
                            color: $primary-color;
                            border: none;
                            cursor: pointer;
                            transition: all 0.15s ease-in;

                            &:hover {
                                color: #3387FF;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .edit-btn {
                display: flex;
                flex: 0 0 100%;
                max-width: 25%;
                padding-left: 1%;
                justify-content: flex-end;
                align-items: flex-start;

                button {
                    background-color: transparent;
                    border: none;
                    font-size: 16px;
                    font-weight: 400;
                    color: $primary-color;
                    cursor: pointer;
                }
            }
        }
    }
}

.loading-message {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .edit-btn {
        button {
            text-align: right;
        }
    }
}