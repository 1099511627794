@import "index.scss";

.form-input-field {
    font-size: 14px;
    width: 400px;
    border-radius: 3px;
    border: 1px solid #8390AF;
    padding: 12px 15px;
    font-family: "Helvetica Neue", sans-serif;
    transition: all .25s cubic-bezier(.645,.045,.355,1);

    &:hover {
        border-color: $primary-color;
    }
}