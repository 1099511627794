@import "./index.scss";

.modal {
    width: clamp(25%, 500px, 90%);
    height: auto;
    margin: auto;
    padding: 32px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dfdfdf;

    .old-qty {
        color: #ca0c0c;
        font-size: 13px;
        text-decoration: line-through;
    }

    .new-qty {
        margin-left: 3px;
        font-weight: 500;
        color: $green-dark;
    }

    p {
        font-size: 24px !important;
        font-weight: 400 !important;
        color: #000 !important;
    }

    table {
        margin: 25px 0;
        width: 100%;
    }

    .btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;

        .delete-btn {
            margin-top: 0 !important;
            color: #fff !important;
            padding: 16px;
            border: none;
            cursor: pointer;
            border-radius: 3px;
            text-decoration: none !important;
            background-color: #ca0c0c !important;

            &:hover {
                color: #fff !important;
            }
        }

        .cancel-btn {
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 16px;
            border-radius: 3px;
            transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

            &:hover {
                background-color: #f1f1f1;
                color: #555;
            }
        }

        .save-btn {
            padding: 16px;
            border: none;
            cursor: pointer;
            margin-top: 0 !important;
            color: #fff !important;
            border-radius: 3px;
            text-decoration: none !important;
            background-color: $primary-color !important;
            transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

            &:hover {
                color: #fff !important;
                background-color: #3387FF !important;
            }
        }
    }
}

.add-location-modal {
    width: auto;

    .selection-wrapper {
        margin-top: 25px;
        margin-bottom: 50px;
        padding-bottom: 0;
        border: none;
    }
}