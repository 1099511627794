@import "./index.scss";

.parts-table {
    border-collapse: collapse;
    border: 2px solid #E5E8EC;
    border-radius: 3px;
    width: 500px;

    .table-heading {
        background-color: #F2F5F9;
        
        tr {
            th {
                padding: 8px 16px;
                font-size: 14px;
                font-weight: 500;
                color: #031b4e;
                text-align: left;
                border-bottom: 2px solid #E5E8EC;

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

    .table-body {
        tr {
            transition: all 0.1s ease-in;
            padding: 1rem;

            td {
                border-bottom: 2px solid #E5E8EC;
                padding: 10px 0;
                color: #031b4e;
                font-size: 14px;

                &:first-child {
                    padding-left: 16px;
                }

                &:last-child {
                    padding-right: 16px;
                    padding-left: 16px;
                    text-align: left;
                }
            }


            .location-td {

                div {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }
            }

            .bold-td {
                font-weight: 700;
                text-align: left;
            }
        }
    }
}